import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'

import VueAMap from "vue-amap";
Vue.use(VueAMap);
import {
  Carousel,
  CarouselItem,
  Notification,
  Message,
  Button,
  MessageBox,
  Dialog,
} from 'element-ui';

// 在调用 Vue.use 前，给 Message 添加 install 方法
Message.install = function (Vue, options) {
  Vue.prototype.$message = Message
}

Vue.prototype.$messageBox = MessageBox

Notification.install = function (Vue, options) {
  Vue.prototype.$notify = Notification
}
Vue.use(Carousel).use(CarouselItem).use(Button).use(Message).use(Notification).use(Dialog)

Vue.config.productionTip = false

VueAMap.initAMapApiLoader({
  key: '749990ebe95c9616211149b6ba4ed82a',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')