<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}
ul,
li {
  list-style: none;
}
ul,
li,
p,
div,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}
.container {
  width: 1200px !important;
  margin: 0 auto;
  /* overflow: hidden; */
}

/* 
enter-active 定义进入过渡的结束状态
leave-active 定义离开过渡的结束状态
 */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.1s;
}

/* 
enter定义进入过渡的开始状态
 */
.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}
/* 
leave-to离场动画结束后的状态
 */
.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
